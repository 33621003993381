<template>
  <b-sidebar
    id="city22-customer-form-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template
      #default="{ hide }"
    >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          v-if="editing && editing.email"
          class="mb-0"
        >
          Edit Customer
        </h5>
        <h5
          v-else
          class="mb-0"
        >
          Add Customer
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1 mt-5"
      >
        <b-spinner label="Loading..." />
      </div>
      <!-- BODY -->
      <validation-observer
        v-else
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validateForm"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email|max:191"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="customerData.email"
                type="email"
                :state="validationContext.errors.length > 0 ? false:null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="accepted"
          >
            <b-form-group
              label="Accepted"
              label-for="accepted"
            >
              <b-form-select
                v-model="customerData.accepted"
                label="Accepted"
                :options="acceptanceOptions"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-if="editing && editing.email"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email,
} from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    editing: {
      type: Object,
      default() {
        return null
      },
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      acceptanceOptions: [
        { text: 'Accepted', value: 1 },
        { text: 'Declined', value: 0 },
      ],
      customerData: {
        email: null,
        accepted: null,
      },
      required,
      email,
    }
  },
  watch: {
    editing() {
      if (this.editing && this.editing.email) {
        this.customerData = {
          email: this.editing.email,
          accepted: this.editing.accepted,
        }
      }
    },
  },
  methods: {
    resetForm() {
      this.customerData = {
        email: null,
        accepted: null,
      }

      this.$emit('close', 1)
    },
    validateForm() {
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.loading = true

            this.$http.post(this.$resources.speedConsents.store, {
              email: this.customerData.email,
              accepted: this.customerData.accepted,
            }).then(response => {
              const { status } = response
              switch (status) {
                case 200:
                  this.$notify({ type: 'success', title: this.editing ? 'Customer edited successfully' : 'Customer added successfully', text: '' })
                  this.$emit('refetch-data', 1)
                  this.$emit('update:is-add-new-user-sidebar-active', false)
                  break
                default:
                  this.$notify({ type: 'danger', title: 'Something went wrong', text: 'Service is under-maintenance currently' })
              }
              this.loading = false
            })
              .catch(() => {
                this.$notify({ type: 'danger', title: 'Something went wrong', text: 'Service is under-maintenance currently' })
                this.loading = false
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#city22-customer-form-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
