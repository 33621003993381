<template>
  <div class="speed-customers-list">
    <b-card
      v-if="loading"
      class="data-loader"
    >
      <b-spinner label="Loading..." />
    </b-card>
    <b-card
      v-else
      class="data-list"
    >
      <customer-form
        :is-sidebar-active.sync="isSidebarActive"
        :editing="selectedCustomer"
        @close="selectedCustomer = null"
        @refetch-data="load"
      />
      <b-row>
        <b-col>
          <div class="card-title">
            Customers List
          </div>
        </b-col>
        <b-col>
          <b-button
            class="float-right mb-1"
            @click="isSidebarActive = true"
          >
            <feather-icon icon="PlusIcon" />
            Add New
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <new-table
            :export-file-name="exportFileName"
            :columns="columns"
            :items="items"
            table-name="city-customers-list"
            @edit="edit"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import Moment from 'moment'
import {
  BCard, BButton, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import CustomerForm from '@/views/city-2022/speed-consents/CustomerForm.vue'
import NewTable from '@/views/new-table.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    NewTable,
    CustomerForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      filter: '',
      oldFilter: '',
      loading: false,
      selectedCustomer: null,
      original_items: [],
      exportFileName: `customers-list-${Moment(new Date()).format('YYYY-MM-DD')}`,
      items: [],
      columns: [
        {
          label: 'Email',
          data: 'email',
        },
        {
          label: 'Accepted',
          data: 'accepted',
        },
        {
          label: 'Date',
          data: 'accepted_at',
        },
        {
          label: 'Actions',
          data: 'actions',
          custom_template: {
            template_name: 'city.customers.actions',
          },
          filterable: false,
          sortable: false,
        },
      ],
      isSidebarActive: false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load(filter = null) {
      this.isSidebarActive = false
      this.loading = true
      this.$http.get(this.$resources.speedConsents.index, {
        params: {
          filter,
        },
      }).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.oldFilter = filter
          this.items = data.rows
          this.original_items = data.rows
          this.loading = false
        }
      })
    },
    searchOnTable(val) {
      this.items = this.original_items
      if (val) {
        const rows = []
        const $values = this.findInValues(this.items, val)
        if ($values.length > 0) rows.push(...$values)
        this.items = rows
        this.clientSearch = true
      }
    },
    findInValues(arr, value) {
      let actualValue
      if (String(value).toLowerCase() === 'yes') {
        actualValue = 'true'
      } else if (String(value).toLowerCase() === 'no') {
        actualValue = 'false'
      } else actualValue = value
      return arr.filter(o => Object.entries(o).some(entry => String(entry[1]).toLowerCase().includes(String(actualValue).toLowerCase())))
    },
    edit(item) {
      this.selectedCustomer = item
      this.isSidebarActive = true
    },
  },
}
</script>
<style lang="scss">
.speed-customers-list {
  .card {
    &.data-loader {
     .card-body {
      min-height: 900px;
      display: flex;
      align-items: center;
      justify-content: center;
      }
    }
    .card-body {
      min-height: 900px;
    }

  }
}
</style>
